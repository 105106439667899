.HomePage {
    height: 100vh;
    width: 100vw;
    .Header {
        flex-direction: column;
        height: auto;
        width: 100%;
        margin: 0;
        position: fixed;
        top: 0px;
        z-index: 4;
        background-color: white;

        .HeaderBottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 120px;
            width: 100%;
            padding: 0px 30px;
            background-color: white;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            .HeaderInfo {
                img {
                    width: 300px;
                }
            }
            .HeaderMenu {
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 40px;
                    height: 40px;
                    // filter: invert(100%);
                    cursor: pointer;
                }
                a {
                    background-color: #0718C4;
                    padding: 10px 20px;
                    border-radius: 5px;
                    color: white;
                    margin-right: 30px;
                    cursor: pointer;
                    text-decoration: none;
                    font-size: 15px;
                }
            }
            .HeaderMainMenu {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;
                position: absolute;
                min-width: 100%;
                height: auto;
                background-color: #ffffff;
                // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                border-radius: 0px 0px 0px 50px;
                top: 80px;
                left: 0px;
                padding-top: 30px;
                a {
                    text-decoration: none;
                    padding: 10px;
                    text-align: center;
                    cursor: pointer;
                    font-size: 20px;
                    margin-top: 10px;
                    font-weight: 500;
                    color: #1c1e21d8;
                    width: 100%;
                }
                a:hover {
                    background-color: #1c1e211d;
                }
                .HeaderMainMenuMore {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    width: 100%;
                    padding: 40px 150px;
                    gap: 40px;
                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #0718C4;
                        padding: 15px 30px;
                        border-radius: 0px;
                        color: white;
                        cursor: pointer;
                        text-decoration: none;
                        font-size: 15px;
                        max-width: 400px;
                        img {
                            width: 20px;
                            height: 20px;
                            margin-left: 10px;
                            filter: invert(100%);
                        }
                    }
                }
            }
        }
    }
    .WebSite {
        background-color: white;
        .MainBanner {
            max-width: 100vw;
            min-height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            padding: 100px 30px;
            .MainBannerInfo {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                z-index: 2;
                h1 {
                    color: white;
                    text-align: center;
                    font-size: 50px;
                    span {
                        color: #0718C4;
                    }
                }
                h2 {
                    color: white;
                    text-align: center;
                    font-weight: 400;
                    margin-top: 50px;
                }
                a {
                    background-color: #0718C4;
                    padding: 20px 50px;
                    border-radius: 10px;
                    margin-top: 50px;
                    color: white;
                    cursor: pointer;
                    text-decoration: none;
                    font-size: 25px;
                }
            }
            .MainBannerImg {
                width: 100vw;
                min-height: 100vh;
                position: absolute;
                .MainBannerImgDark {
                    position: absolute;
                    top: 0px;
                    background-color: black;
                    width: 100%;
                    height: 100%;
                }
                img {
                    position: absolute;
                    top: 0px;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    object-fit: cover;
                    opacity: 40%;
                }
            }
        }
        .Services {
            padding: 50px;
            .Services1,
            .Services2,
            .Services3 {
                margin: 50px 0px 100px 0px;
                img {
                    width: 700px;
                    height: 400px;
                    border-radius: 20px;
                    object-fit: cover;

                    border: 2px solid #0718C4;
                }
                h2 {
                    color: black;
                    font-weight: 600;
                    font-size: 30px;
                    margin-top: 40px;
                    border-bottom: 2px solid #0718C4;
                    padding-bottom: 10px;
                }
                p,
                a {
                    text-decoration: none;
                    color: black;
                    font-weight: 400;
                    margin-top: 20px;
                }
            }
        }
        .ServicesCallNow {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            height: 60vh;
            .ServicesCallNowInfo {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                z-index: 2;
                h1 {
                    text-align: center;
                    z-index: 2;
                    color: white;
                }
                a {
                    z-index: 2;
                    background-color: #0718C4;
                    padding: 15px 60px;
                    border-radius: 10px;
                    margin-top: 50px;
                    color: white;
                    text-decoration: none;
                    font-size: 25px;
                    cursor: pointer;
                }
            }
            .ServicesCallNowImg {
                width: 100vw;
                min-height: 60vh;
                position: absolute;
                .ServicesCallNowImgDark {
                    position: absolute;
                    top: 0px;
                    background-color: black;
                    width: 100%;
                    height: 100%;
                }
                img {
                    position: absolute;
                    top: 0px;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    object-fit: cover;
                    opacity: 40%;
                }
            }
        }
        .ServicesMore {
            padding: 50px;
            background-color: white;

            h1 {
                color: #1c1e21;
                font-weight: 600;
                font-size: 30px;
                margin-top: 40px;
                border-bottom: 1px solid #0718C4;
                padding-bottom: 10px;
            }
            .ServicesMoreBoxMain {
                display: flex;
                flex-wrap: wrap;
                height: auto;
                padding-bottom: 50px;
                .ServicesMoreBoxMainLeft,
                .ServicesMoreBoxMainMiddle,
                .ServicesMoreBoxMainRight {
                    .ServicesMoreBox {
                        display: flex;
                        align-items: center;
                        margin: 20px 40px 0px 0px;
                        img {
                            width: 30px;
                        }
                        a {
                            color: #1c1e21;
                            font-weight: 600;
                            font-size: 20px;
                            // margin: 50px 20px 0px 0px;
                            // border-left: 2px solid black;
                            padding-left: 10px;
                        }
                    }
                }
            }
        }
        .BeforeAndAfter {
            height: auto;
            width: 100%;
            padding: 80px 50px 100px 50px;
            h1 {
                color: black;
                font-size: 40px;
                font-weight: 400;
            }
            img {
                margin-top: 50px;
                border-radius: 20px;
                width: 100%;
                height: 400px;
                border: 4px solid white;
            }
            h2 {
                margin-top: 50px;
                color: black;
                font-size: 25px;
                font-weight: 500;
            }
            h3 {
                margin-top: 30px;
                color: black;
                font-size: 18px;
                font-weight: 400;
            }
        }
        .BookAnAppointmentMain {
            display: flex;
            padding: 100px 50px;
            width: 100%;
            height: auto;
            min-height: 70vh;
            gap: 60px;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            padding: 100px 30px;
            .BookAnAppointmentMainLeft {
                width: 80%;
                z-index: 2;

                h1 {
                    color: #0718C4;
                    font-size: 30px;
                    font-size: 400;
                    text-align: center;
                }
                p {
                    text-align: center;
                    color: white;
                    font-size: 20px;
                    font-size: 400;
                    margin-top: 20px;
                }
            }
            .BookAnAppointmentMainRight {
                width: 60%;
                z-index: 2;
                h1 {
                    color: white;
                    font-size: 25px;
                    font-size: 400;
                    text-align: center;
                }
                button {
                    background-color: #0718C4;
                    padding: 20px 20px;
                    border-radius: 3px;
                    color: white;
                    margin-right: 30px;
                    cursor: pointer;
                    text-decoration: none;
                    font-size: 15px;
                    outline: none;
                    border: none;
                    width: 100%;
                    margin-top: 20px;
                }
            }
            .BookAnAppointmentMainRightImg {
                width: 100vw;
                min-height: 100%;
                position: absolute;
                z-index: 1;
                .MainBannerImgDark {
                    position: absolute;
                    top: 0px;
                    background-color: black;
                    width: 100%;
                    height: 100%;
                }
                img {
                    position: absolute;
                    top: 0px;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    object-fit: cover;
                    opacity: 40%;
                }
            }
        }
        .WhyUs {
            background-color: white;
            border-radius: 0px 0px 50px 50px;
            padding: 50px;
            .WhyUsTitle {
                h1 {
                    color: #1c1e21;
                    font-weight: 600;
                    font-size: 30px;
                    margin-top: 40px;
                    border-bottom: 2px solid #0718C4;
                    padding-bottom: 10px;
                }
                p {
                    color: #1c1e21;
                    font-weight: 400;
                    margin-top: 20px;
                }
            }
            .WhyUsList {
                padding: 50px 0px;
                .WhyUsLkistBox {
                    display: flex;
                    margin-top: 30px;
                    .WhyUsLkistBoxLeft {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: 60px;
                            border-radius: 50%;
                            padding: 10px;
                            background-color: #0718C4;
                        }
                    }
                    .WhyUsLkistBoxRight {
                        padding-left: 30px;
                        h2 {
                            color: #1c1e21;
                            font-weight: 600;
                            font-size: 25px;
                        }
                        h3 {
                            color: #1c1e21;
                            font-weight: 300;
                            margin-top: 20px;
                            font-size: 17px;
                        }
                    }
                }
            }
        }
    }
    .Footer {
        background-color: #1c1e21;
        .FooterFormMain {
            padding: 100px 50px;
            .FooterFormTitle {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                h1 {
                    color: white;
                }
            }
            .FooterForm {
                form {
                    h3 {
                        color: white;
                        font-weight: 500;
                        font-size: 20px;
                        margin-top: 15px;
                    }
                    input,
                    button {
                        margin-top: 10px;
                        width: 100%;
                        height: 40px;
                        border-radius: 5px;
                        border: none;
                    }
                    input:last-child,
                    button {
                        background-color: #0718C4;
                        color: white;
                        margin-top: 20px;
                        cursor: pointer;
                    }
                }
            }
        }
        .FooterContactUs {
            .FooterContactUsTitle {
                padding: 0px 50px;

                h2 {
                    color: white;
                    font-weight: 600;
                    font-size: 30px;
                }
                h3 {
                    color: #d4d4d4;
                    font-weight: 400;
                    margin-top: 20px;
                }
            }
            .FooterContactUsInfo {
                padding: 0px 50px;
                margin: 40px 0px 80px 0px;
                .FooterContactUsInfoBox {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    img {
                        width: 25px;
                        height: 25px;
                        margin-right: 10px;
                    }
                    h2 {
                        color: #d4d4d4;
                        font-weight: 400;
                        span,
                        a {
                            text-decoration: none;
                            color: #0718C4;
                            font-weight: 400;
                            margin-top: 20px;
                        }
                    }
                }
            }
            .FooterContactUsAbout {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                background-color: #1c1e21;
                height: 300px;
                width: 100%;
                margin-top: 50px;
                padding-bottom: 80px;
                h2 {
                    text-align: center;
                    font-size: 18px;
                    font-weight: 400;
                    color: white;
                }
                h3 {
                    text-align: center;
                    font-size: 18px;
                    font-weight: 400;
                    border-top: 1px solid white;
                    padding-top: 30px;
                    margin-top: 30px;
                    color: white;

                    span {
                        cursor: pointer;
                        color: #0718C4;
                    }
                }
            }
        }
    }
    .CallNow {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0718C4;
        bottom: 0;

        cursor: pointer;
        width: 100%;
        padding: 20px 50px;
        z-index: 5;
        a {
            color: white;
            text-decoration: none;
            font-size: 25px;
            font-weight: 600;
        }
    }
    .BookAnAppointmentButton {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0718C4;
        border: 2px solid white;
        cursor: pointer;
        width: 40px;
        height: 150px;
        position: fixed;
        top: 60%;
        left: 0;
        scale: rotate(30px);
        z-index: 10;
        h1 {
            font-size: 14px;
            color: white;
            writing-mode: vertical-rl;
        }
    }
}
@media screen and (min-width: 1400px) {
    .HomePage {
        .Header {
            .HeaderTop {
                padding: 0px 15%;
            }
            .HeaderBottom {
                padding: 0px 15%;
            }
        }
        .WebSite {
            .MainBanner {
                padding: 0px 15%;
            }
            .Services {
                padding: 0px 15%;
            }
            .ServicesCallNow {
            }
            .ServicesMore {
                padding: 50px 15%;
            }
            .BeforeAndAfter {
                padding: 100px 15%;
            }
            .BookAnAppointmentMain {
                padding: 0px 15%;
            }
            .WhyUs {
                padding: 50px 15%;
            }
        }
        .Footer {
            .FooterFormMain {
                padding: 100px 15%;
            }
            .FooterContactUs {
                .FooterContactUsTitle {
                    padding: 0px 15%;
                }
                .FooterContactUsInfo {
                    padding: 0px 15%;
                }
                .FooterContactUsAbout {
                    padding: 0px 15%;
                }
            }
        }
        // display: none;
    }
}
@media screen and (max-width: 800px) {
    .HomePage {
        // display: none;
        .Header {
            .HeaderTop {
                // padding: 0px 15%;
            }
            .HeaderBottom {
                // padding: 0px 15%;
            }
        }
        .WebSite {
            .MainBanner {
                // min-height: 90vh;
                padding: 100px 30px;
            }
            .Services {
                padding: 30px;
                .Services1,
                .Services2,
                .Services3 {
                    img {
                        width: 100%;
                    }
                }
            }
            .ServicesCallNow {
                .ServicesCallNowInfo {
                    padding: 30px;
                    h1 {
                        font-size: 25px;
                    }
                }
            }
            .ServicesMore {
                padding: 30px;
            }
            .BeforeAndAfter {
                padding: 100px 30px;
            }
            .BookAnAppointmentMain {
                padding: 30px;
            }
            .WhyUs {
                padding: 50px 30px;
            }
        }
        .Footer {
            .FooterFormMain {
                padding: 100px 30px;
            }
            .FooterContactUs {
                .FooterContactUsTitle {
                    padding: 0px 30px;
                }
                .FooterContactUsInfo {
                    padding: 0px 30px;
                }
                .FooterContactUsAbout {
                    padding: 0px 30px;
                }
            }
        }
    }
}
@media screen and (max-width: 550px) {
    .HomePage {
        // display: none;
        .Header {
            .HeaderTop {
                padding: 20px 5px;
            }
            .HeaderBottom {
                // flex-direction: column;
                height: 60px;
                padding: 10px 10px;
                .HeaderInfo {
                    img {
                        width: 200px;
                    }
                }
                .HeaderMenu {
                    img {
                        width: 30px;
                        height: 30px;
                    }
                    a {
                        margin-right: 10px;
                        font-size: 15px;
                        padding: 15px 10px;
                    }
                }
                .HeaderMainMenu {
                    width: 100%;
                    top: 110px;
                    padding-top: 30px;
                    border-radius: 0px 0px 0px 20px;
                    .HeaderMainMenuMore {
                        flex-direction: column;
                        width: 100%;
                        padding: 20px 20px 40px 20px;
                        gap: 10px;
                        a {
                            padding: 15px 10px;
                            border-radius: 5px;
                            font-size: 15px;
                            width: 100%;
                        }
                    }
                }
            }
        }
        .WebSite {
            .MainBanner {
                // min-height: 90vh;
                padding: 100px 30px;
                .MainBannerInfo {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    z-index: 2;
                    h1 {
                        font-size: 30px;
                    }
                    h2 {
                        font-size: 20px;
                    }
                }
            }
            .Services {
                padding: 30px;
                .Services1,
                .Services2,
                .Services3 {
                    margin: 50px 0px 70px 0px;

                    img {
                        width: 100%;
                        height: 300px;
                    }
                    h2 {
                        font-size: 20px;
                    }
                }
            }
            .ServicesCallNow {
                .ServicesCallNowInfo {
                    padding: 30px;
                    h1 {
                        font-size: 20px;
                    }
                }
            }
            .ServicesMore {
                padding: 30px;
                h1 {
                    margin-top: 20px;
                    font-size: 25px;
                }
                .ServicesMoreBoxMain {
                    padding-bottom: 20px;
                    .ServicesMoreBox {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        h3 {
                            color: #1c1e21e6;
                            font-weight: 500;
                            font-size: 20px;
                        }
                    }
                }
            }
            .BeforeAndAfter {
                padding: 50px 30px;
                h1 {
                    font-size: 30px;
                }
                img {
                    height: 250px;
                }
                h3 {
                    font-size: 15px;
                }
            }
            .BookAnAppointmentMain {
                padding: 30px;
                .BookAnAppointmentMainLeft {
                    width: 100%;
                    h1 {
                        font-size: 24px;
                    }
                    p {
                        font-size: 16px;
                    }
                }
                .BookAnAppointmentMainRight {
                    width: 100%;
                    padding-bottom: 20px;
                    h1 {
                        font-size: 20px;
                    }
                    button {
                        margin-top: 30px;
                    }
                }
            }
            .WhyUs {
                padding: 50px 30px;
                .WhyUsTitle {
                    p {
                        font-size: 14px;
                    }
                }
                .WhyUsList {
                    .WhyUsLkistBox {
                        margin-top: 30px;
                        .WhyUsLkistBoxRight {
                            padding-left: 30px;
                            h2 {
                                font-size: 20px;
                            }
                            h3 {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
        .Footer {
            .FooterFormMain {
                padding: 100px 30px;
            }
            .FooterContactUs {
                .FooterContactUsTitle {
                    padding: 0px 30px;
                    h2 {
                        font-size: 19px;
                    }
                    h3 {
                        font-size: 15px;
                    }
                }
                .FooterContactUsInfo {
                    padding: 0px 30px;
                    .FooterContactUsInfoBox {
                        h2 {
                            font-size: 15px;
                        }
                    }
                }
                .FooterContactUsAbout {
                    padding: 0px 30px;
                    h2 {
                        font-size: 14px;
                    }
                    h3 {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
