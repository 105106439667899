.ThankPage {
    height: 100vh;
    width: 100vw;
    background-color: white;
    overflow: hidden;

    .Header {
        flex-direction: column;
        height: auto;
        width: 100%;
        margin: 0;
        position: fixed;
        top: 0px;
        z-index: 4;
        .HeaderBottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 80px;
            width: 100%;
            padding: 0px 30px;
            background-color: #eeeeee;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            .HeaderInfo {
                h1 {
                    color: #1c1e21;
                    font-size: 25px;
                    font-size: 400;
                    transform: scale(0.9, 1.7);
                    // background-color: red;
                    span {
                        color: #2ea8ee;
                    }
                }
            }
            .HeaderMenu {
                display: flex;
                align-items: center;
                justify-content: center;
                a {
                    background-color: #2ea8ee;
                    padding: 10px 20px;
                    border-radius: 5px;
                    color: white;
                    margin-right: 30px;
                    cursor: pointer;
                    text-decoration: none;
                    font-size: 15px;
                }
            }
        }
    }
    .BookAnAppointmentMainComponentThanks {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 40px 0px;
        margin-top: 250px;
        h3 {
            text-align: center;
            font-weight: 400;
        }
        h5 {
            text-align: center;
            width: 300px;
            cursor: pointer;
            color: white;
            background-color: #2ea8ee;
            border-radius: 10px;
            padding: 20px 20px;
            margin-top: 25px;
            font-size: 17px;
            font-weight: 500;
        }
    }
}

@media screen and (min-width: 1400px) {
    .ThankPage {
        .Header {
            .HeaderTop {
                padding: 0px 15%;
            }
            .HeaderBottom {
                padding: 0px 15%;
            }
        }
    }
}
@media screen and (max-width: 800px) {
    .ThankPage {
        .Header {
            .HeaderTop {
                // padding: 0px 15%;
            }
            .HeaderBottom {
                // padding: 0px 15%;
            }
        }
    }
}
@media screen and (max-width: 550px) {
    .ThankPage {
        .Header {
            .HeaderTop {
                padding: 20px 5px;
            }
            .HeaderBottom {
                height: 60px;
                padding: 10px 10px;
                .HeaderInfo {
                    h1 {
                        font-size: 15px;
                    }
                }
                .HeaderMenu {
                    img {
                        width: 30px;
                        height: 30px;
                    }
                    a {
                        margin-right: 20px;
                        font-size: 13px;
                    }
                }
                .HeaderMainMenu {
                    width: 100%;
                    top: 110px;
                    padding-top: 30px;
                    border-radius: 0px 0px 0px 20px;
                    .HeaderMainMenuMore {
                        flex-direction: column;
                        width: 100%;
                        padding: 20px 20px 40px 20px;
                        gap: 10px;
                        a {
                            padding: 15px 10px;
                            border-radius: 5px;
                            font-size: 15px;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
